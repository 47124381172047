import { Injectable } from "@angular/core";
import { CacheHeaders } from "../cache-interceptor/cache-headers";
import { EnvironmentType } from "./config.model";
import { BehaviorSubject, Observable } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  private static isConfigReadySubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  static isConfigReady$: Observable<boolean> = ConfigService.isConfigReadySubject.asObservable()
    .pipe(filter((isReady: boolean) => isReady));
  static markConfigAsReady(): void {
    ConfigService.isConfigReadySubject.next(true);
  }

  get environment() {
    return ConfigService.environmentGlobal;
  }

  static environmentGlobal: {
    appVersion: string;
    cache: {
      noCache: {
        [CacheHeaders.TTL]: string;
        [CacheHeaders.CacheType]: string;
      };
      shortLived: {
        [CacheHeaders.TTL]: string;
        [CacheHeaders.CacheType]: string;
      };
      mediumLived: {
        [CacheHeaders.TTL]: string;
        [CacheHeaders.CacheType]: string;
      };
      longLived: {
        [CacheHeaders.TTL]: string;
        [CacheHeaders.CacheType]: string;
      };
    };

    rootSite: {
      url: string;
    };

    auth?: {
      saml?: {
        serviceProviderEntityId: string;
        identityProviderUrl: string;
      };
    };

    google?: {
      auth2?: {
        client_id: string;
        cookiepolicy: string;
        script_src: string;
      },
      maps?: {
        key: string
      }
    };

    apple?: {
      auth2?: {
        client_id: string;
        redirect_url: string;
        script_src: string;
      };
    };

    applications?: {
      url: string;
    };

    loans?: {
      url: string;
    };

    oauth?: {
      url: string;
    };

    doc: {
      url: string;
    };

    companies?: {
      url: string;
    };

    entities?: {
      url: string;
    };

    brokers?: {
      url: string;
    };

    banking?: {
      url: string;
    };

    mfa?: {
      url: string;
    };

    competitors?: {
      url: string;
    };

    salesforceUidResolver?: {
      url: string;
    }

    sentry?: {
      proxy: string;
      enabled: boolean;
      dsn: string;
      environment: EnvironmentType,
      trace_propagation_targets: string[],
      debug?: boolean;
    }
  } = null;
}
