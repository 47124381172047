export class OfferCalculatorHelper {
  /**
    * This method calculates the rounded value in the same way it is done on the backend.
    * The key distinction lies in how it handles cases when the rounding value reaches 0.5
    * this approach is widely known as "bankers' rounding" and is used as the primary
    * method of rounding in IEEE 754 standards.
    * @param value Value to round.
    * @param decimalPlaces Rounded floating point distance.
    * 
    * For more information on IEEE 754, you can refer to the IEEE 754 Wikipedia page [here](https://en.wikipedia.org/wiki/IEEE_754).
    */
  public static bankersRound(value: number, decimalPlaces: number = 0) {
    var scaledValue = value * Math.pow(10, decimalPlaces);
    var roundedScaledValue = Math.round(scaledValue);
    var bankersRoundedValue = Math.abs(scaledValue) % 1 === 0.5 ?
      (roundedScaledValue % 2 === 0 ?
        roundedScaledValue :
        roundedScaledValue - 1
      ) :
      roundedScaledValue;
    return bankersRoundedValue / Math.pow(10, decimalPlaces);
  }
}