import { Address } from "../address/address.model";
import { LinkingStatus } from "../bank/bank.model";
import { parseNumber } from "../helpers/parse.helper";
import { OfferBundleStatus, OfferStatus, OfferType } from "../offer/models/offer.model";
import { ProductCode } from "../product/product.model";
import { QueryParams, QueryResult } from "../query/query.data";

export interface ApplicationBaseData {
    id?: number;
    uuid?: string;
    uniqueId?: string;
    applicationNumber?: string;
    stage?: ApplicationStage;
    sfAppStatus?: ApplicationStatus;
    offerPageUrl?: string;
    entityId?: number;
    entityName?: string;
    entityNumber?: string;
    entityDBA?: string;
    entityEIN?: string;
    entityWebSite?: string;
    entityPhone?: string;
    entityAddress?: Address;
    accountId?: number;
    accountName?: string;
    createdOn?: Date;
    createdBy?: string;
    updatedOn?: Date;
    updatedBy?: string;
    advisorId?: number;
    advisorFirstName?: string;
    advisorLastName?: string;
    advisorName?: string;
    advisorEmail?: string;
    advisorPhone?: string;
    advisorCalendarLinkUrl?: string;
    brokerId?: number;
    brokerName?: string;
    brokerInitialDrawCommision?: number;
    type?: string;
    offerBundles?: ApplicationOfferBundleData[];
    loanId?: number;
    loanNumber?: string;
    renewalForLoanId?: number;
    renewalForLoanNumber?: string;
    renewalForLoanUniqueId?: string;
    renewalReady?: boolean;
    customerIds?: number[];
    customerEmails?: string[];
    contactEmails?: string[];
    linkingAttemptCount?: Number;
    lastLinkingAttemptDate?: Date;
    bankLinkStatus?: LinkingStatus;
    applicationType?: ApplicationType;
    businessName?: string;
}

export enum ApplicationType {
    LCF = 'Lawsuit Cost Financing',
    MSA = 'MSA'
}

export class ApplicationQueryParams extends QueryParams {
    entityId?: number;
    businessName?: string;
    applicationNumber?: string;
    stage?: any;
    companyId?: number;
    createdOnStart?: any;
    createdOnEnd?: any;
    customerId?: number;
    loanNumber?: string;
    bankLinkStatus?: any;
    lastLinkingAttemptDateFrom?: any;
    lastLinkingAttemptDateTo?: any;
    bankLinkAttempted?: boolean;

    constructor(params?: any) {
      super(params);
      params = params || {};

      this.entityId = parseNumber(params.entityId) || undefined;
      this.businessName = params.businessName || undefined;
      this.applicationNumber = params.applicationNumber || undefined;
      this.stage = params.stage || undefined;
      this.companyId = parseNumber(params.companyId) || undefined;
      this.createdOnStart = params.createdOnStart || undefined;
      this.createdOnEnd = params.createdOnEnd || undefined;
      this.customerId = parseNumber(params.customerId) || undefined;
      this.loanNumber = params.loanNumber || undefined;
      this.bankLinkStatus = parseNumber(params.bankLinkStatus);
      this.lastLinkingAttemptDateFrom = params.lastLinkingAttemptDateFrom || undefined;
      this.lastLinkingAttemptDateTo = params.lastLinkingAttemptDateTo || undefined;
      this.bankLinkAttempted = params.bankLinkAttempted == null ? undefined : params.bankLinkAttempted;
    }
}

export interface ApplicationOfferBundleData {
    id: number;
    createdOn: Date;
    createdBy: string;
    updatedOn?: Date;
    updatedBy?: string;
    uniqueId: string;
    status?: OfferBundleStatus;
    statusUpdatedOn?: Date;
    offers?: ApplicationOffer[];
    checkoutRequirements?: ApplicationCheckoutRequirement[];
    businessName?: string;
    expressCheckout?: boolean;
    name?: string;
}

export interface ApplicationOffer {
    id: number;
    createdOn?: Date;
    createdBy?: string;
    updatedOn?: Date;
    updatedBy?: string;
    uniqueId?: string;
    repaymentTerm?: number;
    drawTerm?: number;
    noPaymentTerms?: number;
    interestOnlyPaymentTerms?: number;
    interestRate?: number;
    amount?: number;
    paymentFrequency?: PaymentFrequency;
    drawDownFee?: number;
    drawDownFeeType?: FeeType;
    maintenanceFee?: number;
    maintenanceFeeType?: FeeType;
    maintenanceFeeFrequency?: PaymentFrequency;
    originationFee?: number;
    originationFeeType?: FeeType;
    uccFilingFee?: number;
    uccFilingFeeType?: FeeType;
    servicingFee?: number;
    servicingFeeType?: FeeType;
    servicingFeeMinimum?: number;
    servicingFeeMinimumType?: FeeType;
    stackingFee?: number;
    stackingFeeType?: FeeType;
    renewalFee?: number;
    renewalFeeType?: FeeType;
    productId?: number;
    productCode?: ProductCode;
    productName?: string;
    prepaymentInterestDiscount?: number;
    status?: OfferStatus;
    freezeForDays?: number;
    type?: OfferType;
    maxUpsell?: number;
    name?: string;
    hasDisclosures?: boolean;
}

export interface ApplicationOfferDisclosure {
    code: string;
    name: string;
    html: string;
}

export interface ApplicationCheckoutRequirement {
    id: number;
    createdOn: Date;
    createdBy: string;
    updatedOn?: Date;
    updatedBy?: string;
    uniqueId: string;
    name: string;
    requiredDocument: string;
    isCreditModel: boolean;
    isManual: boolean;
    isFileUploaded: boolean;
    notes: string;
    status: CheckoutRequirementStatus;
}

export enum ApplicationStage {
    New = "New",
    ApplicationCreated = "Application Created",
    ApplicationIncomplete = "Application Incomplete",
    MODALProcessing = "MODAL Processing",
    MODALError = "MODAL Error",
    DeclinedReview = "Declined Review",
    ConditionalOffer = "Conditional Offer",
    Underwriting = "Underwriting",
    UnderwritingMissingInfo = "Underwriting Missing Info",
    UnderwritingReview = "Underwriting Review",
    Approved = "Approved",
    Nurture = "Nurture",
    Accepted = "Accepted",
    Closing = "Closing",
    ClosingReview = "Closing Review",
    ClosingIncomplete = "Closing Incomplete",
    UnderwritingCall = "Underwriting Call",
    SalesReview = "Sales Review",
    PendingSignature = "Pending Signature",
    Open = "Open",
    Frozen = "Frozen",
    Closed = "Closed",
    Dormant = "Dormant",
    Expired = "Expired",
    NotInterested = "NotInterested",
    Declined = "Declined",
    ConditionalApprovalNAICSCheck = "Conditional Approval NAICS Check",
    UnderwritingLitigation = "Underwriting Litigation",
    PendingRenewal = "Pending Renewal"
}

export const ApplicationStageLabel = new Map<string, string>([
    [ApplicationStage.New, 'New'],
    [ApplicationStage.ApplicationCreated, 'Application Created'],
    [ApplicationStage.ApplicationIncomplete, 'Application Incomplete'],
    [ApplicationStage.MODALProcessing, 'MODAL Processing'],
    [ApplicationStage.MODALError, 'MODAL Error'],
    [ApplicationStage.DeclinedReview, 'Declined Review'],
    [ApplicationStage.ConditionalOffer, 'Conditional Offer'],
    [ApplicationStage.Underwriting, 'Underwriting'],
    [ApplicationStage.UnderwritingMissingInfo, 'Underwriting Missing Info'],
    [ApplicationStage.UnderwritingReview, 'Underwriting Review'],
    [ApplicationStage.Approved, 'Approved'],
    [ApplicationStage.Nurture, 'Nurture'],
    [ApplicationStage.Accepted, 'Accepted'],
    [ApplicationStage.Closing, 'Closing'],
    [ApplicationStage.ClosingReview, 'Closing Review'],
    [ApplicationStage.ClosingIncomplete, 'Closing Incomplete'],
    [ApplicationStage.UnderwritingCall, 'Underwriting Call'],
    [ApplicationStage.SalesReview, 'Sales Review'],
    [ApplicationStage.PendingSignature, 'Pending Signature'],
    [ApplicationStage.Open, 'Open'],
    [ApplicationStage.Frozen, 'Frozen'],
    [ApplicationStage.Closed, 'Closed'],
    [ApplicationStage.Dormant, 'Dormant'],
    [ApplicationStage.Expired, 'Expired'],
    [ApplicationStage.NotInterested, 'Not Interested'],
    [ApplicationStage.Declined, 'Declined'],
    [ApplicationStage.ConditionalApprovalNAICSCheck, "Conditional Approval NAICS Check"],
    [ApplicationStage.UnderwritingLitigation, "Underwriting Litigation"],
    [ApplicationStage.PendingRenewal, "Pending Renewal"]
]);

export enum ApplicationStatus {
    ApplicationCreated = "Application Created",
    ApplicationIncomplete = "Application Incomplete",
    Processing = "Processing",
    Underwriting = "Underwriting",
    UnderwritingMissingInfo = "Underwriting Missing Info",
    UnderwritingReview = "Underwriting Review",
    ConditionalOffer = "Conditional Offer",
    Approved = "Approved",
    Accepted = "Accepted",
    UnderwritingCall = "Underwriting Call",
    Closing = "Closing",
    ClosingIncomplete = "Closing Incomplete",
    PendingSignature = "Pending Signature",
    ApplicationComplete = "Application Complete",
    Declined = "Declined",
    Other = "Other",
    ClosingReview = "Closing Review",
    SalesReview = "Sales Review",
    Dormant = "Dormant"
}

export const ApplicationStatusLabel = new Map<string, string>([
    [ApplicationStatus.ApplicationCreated, 'Application created'],
    [ApplicationStatus.ApplicationIncomplete, 'Application incomplete'],
    [ApplicationStatus.Processing, 'Processing'],
    [ApplicationStatus.Underwriting, 'Underwriting'],
    [ApplicationStatus.UnderwritingMissingInfo, 'Underwriting missing info'],
    [ApplicationStatus.UnderwritingReview, 'Underwriting review'],
    [ApplicationStatus.ConditionalOffer, 'Conditional offer'],
    [ApplicationStatus.Approved, 'Approved'],
    [ApplicationStatus.Accepted, 'Accepted'],
    [ApplicationStatus.UnderwritingCall, 'Underwriting call'],
    [ApplicationStatus.Closing, 'Closing'],
    [ApplicationStatus.ClosingIncomplete, 'Closing incomplete'],
    [ApplicationStatus.PendingSignature, 'Pending signature'],
    [ApplicationStatus.ApplicationComplete, 'Application complete'],
    [ApplicationStatus.Declined, 'Declined'],
    [ApplicationStatus.Other, 'Other'],
    [ApplicationStatus.ClosingReview, 'Closing review'],
    [ApplicationStatus.SalesReview, 'Sales review'],
    [ApplicationStatus.Dormant, 'Dormant']
]);

export enum CheckoutRequirementStatus {
  Required = 'required',
  Waived = 'waived',
  Duplicate = 'duplicate',
  Approved = 'approved',
  NotRequiredForOfferSelected = 'not-required-for-offer-selected'
}

export const CheckoutRequirementStatusLabel = new Map<string, string>([
  [CheckoutRequirementStatus.Required, 'Required'],
  [CheckoutRequirementStatus.Waived, 'Waived'],
  [CheckoutRequirementStatus.Duplicate, 'Duplicate'],
  [CheckoutRequirementStatus.Approved, 'Approved'],
  [CheckoutRequirementStatus.NotRequiredForOfferSelected, 'Not required for offer selected'],
]);

export enum FeeType {
    Percentage = 'percentage',
    Flat = 'flat'
}

export const FeeTypeLabel = new Map<string, string>([
    [FeeType.Percentage, 'Percentage'],
    [FeeType.Flat, 'Flat']
]);

export enum PaymentFrequency {
    Weekly = 'weekly',
    BiWeekly = 'bi-weekly',
    Monthly = 'monthly'
}

export const PaymentFrequencyLabel = new Map<string, string>([
    [PaymentFrequency.Weekly, 'Weekly'],
    [PaymentFrequency.BiWeekly, 'Bi-Weekly'],
    [PaymentFrequency.Monthly, 'Monthly']
]);

export interface ApplicationQueryResult extends QueryResult<ApplicationBaseData> { }
