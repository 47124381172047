import { Injectable } from "@angular/core";
import { ApplicationOffer, ApplicationOfferBundleData, FeeType, OfferCalculatorHelper, Payment, PaymentFrequency } from "common";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CalculatorService {

  bundleChanged: BehaviorSubject<ApplicationOfferBundleData>;

  constructor() {
    this.bundleChanged = new BehaviorSubject<ApplicationOfferBundleData>(null);
  }

  getTotalNumberOfPayments(paymentFrequency: PaymentFrequency, term: number): number {
    switch (paymentFrequency) {
      case PaymentFrequency.BiWeekly:
        return Math.round(term * 26 / 12);

      case PaymentFrequency.Monthly:
        return term;

      case PaymentFrequency.Weekly:
        return Math.round(term * 52 / 12);
    }
  }

  addMonths(date: Date, months: number): Date {
    const d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }

  findClosestBusinessDay(date: Date): Date {
    if (date.getDay() == 0 /* Sunday */)
      date.setDate(date.getDate() + 1);
    else if (date.getDay() == 6 /* Saturday */)
      date.setDate(date.getDate() + 2);
    return date;
  }

  getPaymentCycleStartDate(paymentFrequency: PaymentFrequency): Date {
    const paymentCycleStartDate = new Date();

    switch (paymentFrequency) {
      case PaymentFrequency.BiWeekly:
        return this.findClosestBusinessDay(paymentCycleStartDate);

      case PaymentFrequency.Monthly:
        if (paymentCycleStartDate.getDate() > 28)
          paymentCycleStartDate.setDate(28);
        return paymentCycleStartDate;

      case PaymentFrequency.Weekly:
        return this.findClosestBusinessDay(paymentCycleStartDate);
    }
  }

  addPayPeriod(paymentFrequency: PaymentFrequency, paymentDate: Date): Date {
    const nextPaymentDate = new Date(paymentDate.getTime());
    switch (paymentFrequency) {
      case PaymentFrequency.BiWeekly:
        nextPaymentDate.setDate(paymentDate.getDate() + 14);
        return nextPaymentDate;

      case PaymentFrequency.Monthly:
        return this.addMonths(nextPaymentDate, 1);

      case PaymentFrequency.Weekly:
        nextPaymentDate.setDate(paymentDate.getDate() + 7);
        return nextPaymentDate;
    }
  }

  getTermLoanTotalInterest(offerDetail: ApplicationOffer, term: number): number {
    const nop = this.getTotalNumberOfPayments(offerDetail.paymentFrequency, term);
    const tnop = this.getTotalNumberOfPayments(offerDetail.paymentFrequency, offerDetail.repaymentTerm);
    const totalInterest = Math.round(offerDetail.amount * offerDetail.interestRate) / 100.0;

    if (nop >= tnop)
      return totalInterest;
    else {
      const payments = this.getTermLoanPayments(offerDetail, term);
      const lastPayment = payments[payments.length - 1];
      return Math.round((lastPayment.interestPaid + lastPayment.interestBalance * 0.75) * 100.0) / 100.0;
    }
  }

  getTermLoanPayment(amount: number, totalInterest: number, totalNumberOfPayments: number): number {
    const termLoanPayment = (amount + totalInterest) * 100 / totalNumberOfPayments;
    return Math.ceil(termLoanPayment) / 100;
  }

  getTermLoanPayments(offerDetail: ApplicationOffer, term?: number): Payment[] {
    const amount = offerDetail.amount;
    term = term ?? offerDetail.repaymentTerm;
    const rate = offerDetail.interestRate / 100;
    const paymentFrequency = offerDetail.paymentFrequency;

    const nop = this.getTotalNumberOfPayments(paymentFrequency, term);
    const tnop = this.getTotalNumberOfPayments(paymentFrequency, offerDetail.repaymentTerm);
    const totalInterest = OfferCalculatorHelper.bankersRound(amount * rate * 100) / 100;

    const denominator = tnop / 2 * (tnop + 1);

    let interestBalance = totalInterest;
    let principalBalance = amount;
    let interestPaid = 0;
    let capitalRepayment = 0;
    let totalPayback = 0;

    let paymentDate = this.getPaymentCycleStartDate(paymentFrequency);
    const paymentAmount = this.getTermLoanPayment(amount, totalInterest, nop);

    const payments = new Array();

    for (let idx = 0; idx < nop; idx++) {
      const interestPayment = OfferCalculatorHelper.bankersRound(totalInterest * (tnop - idx) / denominator * 100, 4) / 100;
      const principalPayment = idx != nop - 1 ? paymentAmount - interestPayment : principalBalance;

      interestBalance -= interestPayment;
      interestPaid += interestPayment;
      principalBalance -= principalPayment;
      paymentDate = this.addPayPeriod(paymentFrequency, paymentDate);
      capitalRepayment += principalPayment;
      totalPayback += interestPayment + principalPayment;

      const payment = new Payment();
      payment.label = 'Payment ' + ((idx + 1).toString());
      payment.date = this.findClosestBusinessDay(paymentDate);
      payment.interestAmount = interestPayment;
      payment.principalAmount = principalPayment;
      payment.totalAmount = interestPayment + principalPayment;
      payment.interestBalance = interestBalance;
      payment.principalBalance = principalBalance;
      payment.interestPaid = interestPaid;
      payment.totalDrawFees = 0;
      payment.capitalRepayment = capitalRepayment;
      payment.totalPayback = totalPayback;
      if (offerDetail?.prepaymentInterestDiscount ?? 0 > 0) {
        payment.discountAvailable = OfferCalculatorHelper.bankersRound((payment.interestBalance * offerDetail.prepaymentInterestDiscount / 100) * 100) / 100;
        payment.payoffAmount = payment.principalBalance + payment.interestBalance - payment.discountAvailable;
      }
      
      payments.push(payment);
    }

    return payments;
  }

  getLineOfCreditPayment(amount: number, rate: number, numberOfPaymentsPerAnnum: number, totalNumberOfPayments: number): number {
    const r = rate / numberOfPaymentsPerAnnum;
    return Math.round(amount * r / (1 - Math.pow(1 + r, -totalNumberOfPayments)) * 100) / 100;
  }

  calculateFeeAmount(amount: number, fee: number, feeType: FeeType) {
    return feeType === FeeType.Percentage
      ? Math.round(amount * fee) / 100
      : fee;
  }

  isNoPaymentPeriod(offerDetail: ApplicationOffer, paymentNumber: number) {
    return this.isPositive(offerDetail.noPaymentTerms)
      && paymentNumber <= offerDetail.noPaymentTerms;
  }

  isInterestOnlyPaymentPeriod(offerDetail: ApplicationOffer, paymentNumber: number) {
    return this.isPositive(offerDetail.interestOnlyPaymentTerms)
      && paymentNumber <= this.positiveOrDefault(offerDetail.noPaymentTerms) + offerDetail.interestOnlyPaymentTerms
      && (!this.isPositive(offerDetail.noPaymentTerms) || paymentNumber > this.positiveOrDefault(offerDetail.noPaymentTerms));
  }

  isPositive(value: number) {
    return value && Number.isFinite(value) && value > 0;
  }

  positiveOrDefault(value: number) {
    return this.isPositive(value) ? value : 0;
  }

  getLineOfCreditLoanPayments(offerDetail: ApplicationOffer, amount: number): Payment[] {
    amount = amount ?? offerDetail.amount;
    const term = offerDetail.repaymentTerm;
    const rate = offerDetail.interestRate / 100;
    const paymentFrequency = offerDetail.paymentFrequency;

    const numberOfPaymentsPerAnnum = this.getNumberOfPaymentsPerAnnum(paymentFrequency);
    const nop = this.getTotalNumberOfPayments(paymentFrequency, term);
    const dailyInterestRate = this.getDailyInterestRate(rate);

    const pr = dailyInterestRate * this.getAverageDaysInPayPeriod(paymentFrequency);

    let principalBalance = amount;
    let interestPaid = 0;
    let interestBalance = 0;
    let capitalRepayment = 0;
    let totalPayback = 0;
    let paymentDate = this.getPaymentCycleStartDate(paymentFrequency);

    const paymentAmount = this.getLineOfCreditPayment(amount, rate, numberOfPaymentsPerAnnum, nop - this.positiveOrDefault(offerDetail.noPaymentTerms) - this.positiveOrDefault(offerDetail.interestOnlyPaymentTerms));
    const drawFee = this.calculateFeeAmount(amount, offerDetail.drawDownFee, offerDetail.drawDownFeeType);
    const payments = new Array();

    let accruedInterest = 0;
    let paymentNumber = 1;

    for (let idx = 0; idx < nop; idx++) {
      accruedInterest += Math.round(principalBalance * pr * 100) / 100;
      const interestPayment = accruedInterest;
      let principalPayment = idx != nop - 1 ? paymentAmount - interestPayment : principalBalance;

      paymentDate = this.addPayPeriod(paymentFrequency, paymentDate);

      if (this.isNoPaymentPeriod(offerDetail, idx + 1))
        continue;

      if (this.isInterestOnlyPaymentPeriod(offerDetail, idx + 1))
        principalPayment = 0;

      interestPaid += interestPayment;
      accruedInterest -= interestPayment;
      principalBalance -= principalPayment;
      capitalRepayment += principalPayment;
      totalPayback += interestPayment + principalPayment;

      const payment = new Payment();
      payment.label = 'Payment ' + (paymentNumber++).toString();
      payment.date = this.findClosestBusinessDay(paymentDate);
      payment.interestAmount = interestPayment;
      payment.principalAmount = principalPayment;
      payment.totalAmount = interestPayment + principalPayment;
      payment.interestBalance = interestBalance;
      payment.principalBalance = principalBalance;
      payment.interestPaid = interestPaid;
      payment.totalDrawFees = drawFee;
      payment.capitalRepayment = capitalRepayment;
      payment.totalPayback = totalPayback;

      payments.push(payment);
    }
    
    return payments;
  }

  getLineOfCreditTotalInterest(offerDetail: ApplicationOffer, amount: number, term: number) {
    const payments = this.getLineOfCreditLoanPayments(offerDetail, amount);
    const nop = this.getTotalNumberOfPayments(offerDetail.paymentFrequency, term);
    const lastPayment = payments[nop - 1];
    return lastPayment?.interestPaid;
  }

  getDailyInterest(principalBalance: number, rate: number): number {
    const numberOfDaysInYear = this.getDaysInYear();
    const apr = rate / 100;

    return principalBalance * apr / numberOfDaysInYear;
  }

  getDailyInterestRate(rate: number): number {
    return rate / this.getDaysInYear();
  }

  getDaysInYear(): number {
    const year = (new Date()).getFullYear();
    return year % 100 === 0 ? (year % 400 === 0 ? 366 : 365) : (year % 4 === 0 ? 366 : 365);
  }

  getAverageDaysInPayPeriod(paymentFrequency: PaymentFrequency): number {
    switch (paymentFrequency) {
      case PaymentFrequency.BiWeekly:
        return 14;

      case PaymentFrequency.Monthly:
        return this.getDaysInYear() / 12;

      case PaymentFrequency.Weekly:
        return 7;
    }
  }

  getNumberOfPaymentsPerAnnum(paymentFrequency: PaymentFrequency): number {
    switch (paymentFrequency) {
      case PaymentFrequency.BiWeekly:
        return 26;

      case PaymentFrequency.Monthly:
        return 12;

      default:
        return 52;
    }
  }
}