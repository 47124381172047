import { OnInit, Directive, Input, AfterContentInit, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { AppBarTitleService, ApplicationOfferBundleData, ApplicationCheckoutRequirement, ApplicationBaseData, ProductCode, ApplicationOffer } from 'common';
import * as _ from 'lodash';
import { CalculatorService } from '../calculator.service';
import { AppSettings } from '../../../app.settings';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApplicationService } from '../../shared/application.service';
import { OfferBundleData } from '../../shared/offer-bundle.service';
import { OfferCalculator } from '../offer-calculator';
import { OfferNavigator } from '../offer-navigator';
import { LocOfferCalculator } from '../loc-offer-calculator';
import { TermOfferCalculator } from '../term-offer-calculator';
import { LawOfferCalculator } from '../law-offer-calculator';

@Directive()
export abstract class BaseCalculatorComponent implements OnInit, AfterContentInit, OnChanges {

    @Input() offer: ApplicationOffer;

    offerCalculator: OfferCalculator;
    navigator: OfferNavigator;

    protected uuid: string;
    offerId: number;
    advisorId: number;
    brandingCss: SafeResourceUrl;
    offerDetails: ApplicationOffer[] = [];
    protected application: ApplicationBaseData;
    bundle: ApplicationOfferBundleData;
    checkoutRequirements: ApplicationCheckoutRequirement[];
    offerBundles: OfferBundleData[];
    isRenewal: boolean;
    customerView: boolean;

    protected _unsubscribeAll: Subject<any>;

    constructor(
        protected _route: ActivatedRoute,
        private _sanitizer: DomSanitizer,
        protected _appBarTitleService: AppBarTitleService,
        protected _calculatorService: CalculatorService,
        private applicationService: ApplicationService,
        protected settings: AppSettings,
        private percentPipe: PercentPipe,
        private currencyPipe: CurrencyPipe
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit() {
        this._route.data.subscribe(this.dataInit.bind(this));
        this._route.queryParamMap.subscribe(params => {

            let subdomain = null;

            if (!environment.production)
                subdomain = params.get("subdomain");

            if (!subdomain)
                // eslint-disable-next-line max-len
                this.brandingCss = this._sanitizer.bypassSecurityTrustResourceUrl(`${this.settings.brokers.url}/api/branding/loc-calculator-css`);
            else
                // eslint-disable-next-line max-len
                this.brandingCss = this._sanitizer.bypassSecurityTrustResourceUrl(`${this.settings.brokers.url}/api/branding/loc-calculator-css?subdomain=${subdomain}`);

            this.uuid = params.get("uuid");

            this.applicationService.getByUUID(this.uuid)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(this.applicationDataInit.bind(this));
        });
    }

    applicationDataInit(data: ApplicationBaseData) {
        this.application = data;
        this.isRenewal = data.renewalForLoanUniqueId ? true : false;
        this.navigator = new OfferNavigator(data.offerBundles, this.bundle.id);
        if (this.offerCalculator) {
            this.offerCalculator.application = data;
            this.offerCalculator.brokerInitialDrawCommision = data.brokerInitialDrawCommision / 100;
        }
    }

    dataInit(data: any) {
        this.customerView = data.preview;
        this.bundle = data.bundle;
        this._calculatorService.bundleChanged.next(this.bundle);
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.ngAfterContentInit();
    }

    ngAfterContentInit(): void {
        this.offerCalculator = this.createOfferCalculator();
        this.checkoutRequirements = this.bundle?.checkoutRequirements;
    }

    createOfferCalculator(): OfferCalculator {
        switch(this.offer.productCode) {
            case ProductCode.LOC: return new LocOfferCalculator(this.application, this.offer, this.bundle, this.percentPipe, this.currencyPipe, this._calculatorService);
            case ProductCode.Term: return new TermOfferCalculator(this.application, this.offer, this.bundle, this.percentPipe, this.currencyPipe, this._calculatorService);
            case ProductCode.Law: return new LawOfferCalculator(this.application, this.offer, this.bundle, this.percentPipe, this.currencyPipe, this._calculatorService);
            default: return null;
        }
    }
}
